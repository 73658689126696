@media (max-width:1050px) {
    body .sec-serv-text h2 {
        font-size: var(--medium);
    }

    body .sec-serv-text {
        padding-left: 20px;
    }
}

@media (max-width:850px) {
    body .sec-serv-text h2 {
        font-size: var(--xmedium);
    }

    body .sec-serv-text {
        padding-left: 20px;
    }

    body .ter-serv {
        display: block;
    }
}

@media (max-width:700px) {
    body .sec-serv-text h2 {
        font-size: var(--xmedium);
    }

    body .sec-serv-text {
        padding-top: 20px;
        padding-left: 20px;
    }

    body .sec-serv {
        display: block;
    }
}

@media (max-width:500px) {
    body .sec-serv-text h2 {
        font-size: var(--xmedium);
    }

    body .sec-serv-text {
        padding-top: 20px;
        padding-left: 20px;
    }

    body .sec-serv {
        display: block;
    }

    body .sec-serv-container {
        width: 300px;
        gap: 10px;
    }

    body .pri-serv h1 {
        font-size: var(--medium);
    }
}

.pri-serv {
    padding: 50px;
    min-height: 500px;
}

.pri-serv h1 {
    font-family: "lexend-bold";
    font-size: var(--big);
    padding-bottom: 30px;
}

.sec-serv {
    background-image: linear-gradient(to bottom, rgba(32, 44, 84,0.5),rgba(32, 44, 84,0.5)), url(../assets/servicios_esc.png);
    background-position: 0 30%;
    background-size: cover;
    color: white;
    padding: 50px;
    min-height: 800px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.sec-serv-container {
    width: 400px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
}

.sec-serv-container aside {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    height: 190px;
    font-size: var(--medium);
    cursor: pointer;
}

.sec-serv-container aside h3 {
    font-size: var(--small);
    font-family: "lexend-regular";
}

.active-pri-serv {
    background-color: var(--amarillo);
    color: var(--azul-oscuro);
}

.def-pri-serv {
    border: 5px solid var(--amarillo);
    color: var(--amarillo);
    transition: all 0.25s ease;
}

.def-pri-serv:hover {
    background-color: var(--amarillo);
    color: var(--azul-oscuro);
    border: 0;
}

.sec-serv-text h2{
    font-family: "lexend-bold";
    font-size: var(--big);
    color: var(--amarillo);
    padding-bottom: 20px;
}

.ter-serv {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
    padding: 100px 0px;
}

.ter-serv section {
    padding: 50px;
    max-height: 900px;
}

.ter-serv section aside {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
}

.bloq-azul {
    background-color: var(--azul-oscuro);
    color: var(--celeste);
    font-family: "arimo-regular";
    font-size: var(--small);
}

#bloq-az-1 {
    background-image: linear-gradient(to bottom, rgba(32, 44, 84,0.6),rgba(32, 44, 84,0.6)), url(../assets/seleccion_personal_esc.png);
    background-position: center;
    background-size: cover;
}

#bloq-az-2 {
    background-image: linear-gradient(to bottom, rgba(32, 44, 84,0.6),rgba(32, 44, 84,0.6)), url(../assets/performance_equipos_esc.png);
    background-position: 0 10%;
    background-size: cover;
}

.bloq-azul h2 {
    color: var(--amarillo);
    font-family: "lexend-bold";
    font-size: var(--big);
    padding-bottom: 20px;
}

.bloq-celeste {
    background-color: var(--celeste);
    color: white;
    font-family: "arimo-regular";
    font-size: var(--small);
}

.bloq-celeste h2 {
    color: var(--azul-oscuro);
    font-family: "lexend-bold";
    font-size: var(--big);
    padding-bottom: 20px;
}