#sticky-wh{
    position: fixed;
    bottom: 40px;
    right: 40px;
    font-family: "arimo-regular";
    font-size: var(--small);
    padding: 20px;
    color: white;
    background-color: var(--azul-oscuro);
    border-radius: 10px;
    z-index: 100;
    text-align: center;
    cursor: pointer;
    border: 1px solid var(--amarillo);
    transition: all ease 0.3s;
}

#sticky-wh:hover {
    padding: 25px;
    font-size: 18px;
}