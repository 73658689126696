@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swiper-servicios-landing .swiper-pagination-bullet {
  background-color: #B8D1EB !important;
  width: 14px !important;
  height: 14px !important;
  border-radius: 50% !important;
  opacity: 0.5 !important;
  transition: all 0.3s ease-in-out !important;
}

/* Resaltar la bolita activa */
.swiper-servicios-landing .swiper-pagination-bullet-active {
  background-color: #202C54 !important;
  opacity: 1 !important;
  width: 18px !important;
  height: 18px !important;
  border: 2px solid #B8D1EB !important;
}
