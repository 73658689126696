@media (max-width:550px) {

    body .sec-inicio {
        padding: 12.5px;
    }

    body #nuestra-mision aside {
        display: none;
    }

    body #nuestra-mision section {
        width: 100%;
    }

    body .pri-inicio h1 {
        font-size: var(--xmedium);
    }
}

@media (max-width:450px) {
    body .sec-inicio-container aside {
        width: 400px;
        height: 400px;
        border-radius: 50px;
        margin-top:50px;
        margin-bottom: 50px;
        padding: 50px;
    }

    body .sec-inicio-container aside button {
        bottom: 50px;
        padding: 10px;
        border-radius: 50px;
        font-size: var(--small);
    }

    body .sec-inicio-container aside h3 {
        font-size: var(--small);
    }

    body .sec-inicio-container aside p {
        font-size: var(--xsmall);
    }
}

@media (max-width:350px) {

    body .sec-inicio-container aside {
        border-radius: 50px;
        margin-top:25px;
        margin-bottom: 25px;
        padding: 25px;
    }

    body .sec-inicio-container aside button {
        bottom: 25px;
        padding: 10px;
        font-size: var(--small);
    }

    body .sec-inicio-container aside h3 {
        font-size: var(--small);
    }

    body .sec-inicio-container aside p {
        font-size: var(--xsmall);
    }

    body .pri-inicio h1 {
        font-size: var(--small);
    }

    body .sec-inicio {
        padding: 20px;
    }

    body .sec-inicio-container aside {
        height: 600px;
    }
}

#inicio {
    background-color: var(--azul-oscuro);
    color: var(--blanco);
}

.pri-inicio {
    padding: 100px;
    min-height: 600px;
    background-image: url(../assets/portada_esc_escucha_soluciona_crece.png);
    background-position: center;
    background-size: cover;
    color: black;
}

.pri-inicio h1 {
    font-family: "lexend-bold";
    font-size: var(--big);
    max-width: 700px;
}

.pri-inicio p {
    font-family: "arimo-regular";
    padding-top: 20px;
    font-size: var(--small);
    padding-bottom: 20px;
}

.pri-inicio a {
    border: 0;
    color: var(--azul-oscuro);
    background-color: var(--amarillo);
    border-radius: 20px;
    padding: 10px 20px;
    font-family: "arimo-bold";
    font-size: var(--small);
    cursor: pointer;
    transition: all 0.25s ease-in-out;
}

.pri-inicio a:hover {
    color: var(--amarillo);
    background-color: var(--azul-oscuro);
}

.sec-inicio {
    padding: 50px;
    background-color: var(--azul-oscuro);
}

.sec-inicio-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.sec-inicio-container aside {
    background-color: white;
    color: var(--azul-oscuro);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
    height: 400px;
    border-radius: 50px;
    margin: 25px;
    padding: 50px;
}

.sec-inicio-container aside .i-globe {
    font-size: var(--medium);
}

.sec-inicio-container aside h3 {
    font-size: var(--xmedium);
    font-family: "lexend-deca-regular";
    padding-top: 10px;
}

.sec-inicio-container aside p {
    font-size: var(--small);
    padding-bottom: 10px;
    padding-top: 10px;
    font-family: "arimo-regular";
}

.sec-inicio-container aside button {
    bottom: 50px;
    padding: 10px;
    border-radius: 50px;
    background-color: var(--amarillo);
    border: 0;
    cursor: pointer;
    font-family: "lexend-deca-regular";
    font-size: var(--small);
}

.nuestra-mision {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.nuestra-mision section {
    background-color: var(--celeste);
    color: var(--azul-oscuro);
    font-family: "lexend-regular";
    font-size: var(--xmedium);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 50%;
    padding: 50px;
    text-align: center;
}

.nuestra-mision section p {
    padding-top: 10px;
    border-top: 10px solid var(--amarillo);
}

.nuestra-mision section h2 {
    padding-bottom: 10px;
}

.nuestra-mision aside {
    height: 500px;
    width: 50%;
}

.nm1 aside {
    background-image: url(../assets/cultura_laboral_esc.png);
    background-position: bottom;
    background-size: cover;
}

.opc-inicio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 50px;
    gap: 20px;
}

.opc-inicio section {
    width: 300px;
    margin: 25px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 150px;
}

.opc-inicio section .icon-inicio {
    font-size: var(--medium);
    padding-bottom: 20px;
}

.opc-inicio section a {
    border: 0;
    background-color: var(--amarillo);
    border-radius: 50px;
    color: var(--azul-oscuro);
    font-size: var(--xmedium);
    font-family: "lexend-regular";
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
}

.testimionios-tit {
    text-align: center;
    margin: auto;
    padding-top: 50px;
    color: var(--amarillo);
    font-size: var(--medium);
    padding-bottom: 10px;
    border-bottom: var(--amarillo) 2px solid;
    width: fit-content;
}

.testimonios {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    justify-content: center;
    gap: 20px;
}

.testimonios section {
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
    margin: 10px;
}

.testimonios section div {
    text-align: center;
}

.testimonios span {
    color: var(--amarillo);
    font-size: var(--xmedium);
}

.testimonios h2 {
    font-size: var(--medium);
}

.testimonios img {
    border-radius: 50%;
    width: 300px;
    height: 300px;
    padding: 10px;
    border: var(--amarillo) 2px dotted;
    margin: auto;
}

.colaboraciones {
    background-color: var(--celeste);
    padding: 20px;
}

.colaboraciones h2 {
    text-align: center;
    font-size: var(--medium);
    margin: auto;
    width: fit-content;
    border-bottom: var(--amarillo) 2px solid;
    color: var(--amarillo);
}

.colaboraciones img {
    height: 100px;
    margin: 10px;
    padding: 10px;
    
}

.colaboraciones div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--azul-oscuro);
    border-radius: 20px;
    border: var(--amarillo) 2px solid;
    margin: 10px;
}

.colaboraciones div aside {
    margin-bottom: 10px;
}

.colaboraciones section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 50px;
    gap: 20px;
}