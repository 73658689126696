@font-face {
  font-family: "arimo-regular";
  src: url(./fonts/Arimo-Regular.ttf);
}

@font-face {
  font-family: "arimo-medium";
  src: url(./fonts//Arimo-Medium.ttf);
}

@font-face {
  font-family: "arimo-bold";
  src: url(./fonts//Arimo-Bold.ttf);
}

@font-face {
  font-family: "lexend-regular";
  src: url(./fonts/Lexend-Regular.ttf);
}

@font-face {
  font-family: "lexend-light";
  src: url(./fonts/Lexend-Light.ttf);
}

@font-face {
  font-family: "lexend-medium";
  src: url(./fonts/Lexend-Medium.ttf);
}

@font-face {
  font-family: "lexend-bold";
  src: url(./fonts/Lexend-Bold.ttf);
}

@font-face {
  font-family: "lexend-deca-regular";
  src: url(./fonts/LexendDeca-Regular.ttf);
}

@font-face {
  font-family: "lexend-deca-medium";
  src: url(./fonts/LexendDeca-Medium.ttf);
}

@font-face {
  font-family: "lexend-deca-light";
  src: url(./fonts/LexendDeca-Light.ttf);
}

@font-face {
  font-family: "lexend-deca-semibold";
  src: url(./fonts/LexendDeca-SemiBold.ttf);
}

.texto {
  font-family: "lexend-deca-regular";
}

:root {
  --amarillo: #FBB927;
  --azul-oscuro: #202C54;
  --azul-claro:#6089BA;
  --celeste:#B8D1EB;
  --blanco:#ffffff;
  --negro:#000000;
  --xxsmall:12px;
  --xsmall:16px;
  --small:18px;
  --xxmedium:20px;
  --xmedium:24px;
  --medium:36px;
  --big:42px;
  --xbig:60px;
  --xxbig:72px;
}

.texto-azul {
  color: var(--azul-oscuro);
}

.texto-amarillo {
  color: var(--amarillo);
}

.bg-azul {
  background-color: var(--azul-oscuro);
}

.bg-amarillo {
  background-color: var(--amarillo);
}

.paralelogramo {
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}

.trapezoide {
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
}

.globo-texto {
  clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
}

.hexagono {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.bevel {
  clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}