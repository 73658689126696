@media (max-width:1050px) {
    body .display main div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    body .active-colaborador {
        width: 300px;
    }

    body .nuestro-equipo {
        padding: 25px;
    }
}

@media (max-width:550px) {
    body .display main div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    body .active-colaborador {
        width: 150px;
    }

    body .nuestro-equipo {
        padding: 15px;
    }

    body .display img {
        width: 150px;
        height: 150px;
    }

    body .display main h2 {
        font-size: var(--xmedium);
    }

    body .nuestro-equipo h1 {
        font-size: var(--medium);
    }
}

.nuestro-equipo {
    padding: 50px;
}

.nuestro-equipo h1 {
    font-family: "lexend-bold";
    font-size: var(--big);
    color: var(--azul-oscuro);
}

.display {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.display img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border: 5px solid var(--amarillo);
    border-radius: 20px; 
}

.display img::before {
    width: 300px;
    height: 300px;
    border: 5px solid var(--amarillo);
    border-radius: 20px;
}

.display main {
    display: flex;
    justify-content: center;
    align-items: center;
}

.display main div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    
}

.display main span {
    color: var(--azul-claro);
    padding: 10px;
}

.display main h2 {
    color: var(--azul-oscuro);
    font-family: "lexend-bold";
    font-size: var(--medium);
    padding-bottom: 20px;
}

.display main ul {
    list-style: circle;
}

.display main li {
    padding-left: 10px;
    color: var(--azul-oscuro);
    font-size: var(--small);
    font-family: "arimo-regular";
}

.active-arrow {
    border: 0;
    background-color: var(--azul-oscuro);
    color: var(--amarillo);
    padding: 10px;
    font-size: var(--small);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 20px;
    cursor: pointer;
}

.def_arrow {
    border: 0;
    background-color: #f2f2f2;
    color: white;
    padding: 10px;
    font-size: var(--small);
    width: 50px;
    height: 50px;
    margin: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.colaborador {
    overflow: hidden;
}

.active-colaborador {
    width: 800px;
}

.def-colaborador {
    width: 0px;
    opacity: 0;
}

.guia-colab {
    margin: auto;
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(6,1fr);
    gap: 10px;
    width: 280px;
}

.guia-colab div {
    background-color: #f2f2f2;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.guia-colab .active-circle {
    background-color: var(--amarillo);
}

.guia-colab .def-circle {
    background-color: var(--azul-claro);
}