@media (max-width:350px) {
    body #footer img {
        width: 150px;
    }
}


#footer {
    padding: 50px;
    padding-bottom: 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: var(--azul-oscuro);
    color: var(--amarillo);
}

#footer img {
    width: 300px;
}

#footer section {
    padding-top: 100px;
}

#footer div button {
    border: none;
    background-color: transparent;
    padding: 10px;
    font-size: var(--medium);
    color: var(--amarillo);
    cursor: pointer;
}