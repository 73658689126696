@media (max-width:1200px) {
    header .lista-nav {
        display: none;
    }

    header .menu {
        display: block;
        font-size: var(--medium);
    }

    header .overlay {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 0%;
        overflow: hidden;
        background-color: var(--azul-oscuro);
        color: white;
        transition: all 0.3s ease 0s;
        z-index: 2;
    }

    header .cancel-nav {
        font-size: var(--medium);
        position: absolute;
        top: 50px;
        left: 50px;
    }

    header .overlay-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        transform: translate(-50%,-50%);
        top: 50%;
        left: 50%;
        list-style: none;
    }

    .overlay-content li {
        padding: 15px;
        text-align: center;
    }
    
    .overlay-content .link {
        text-decoration: none;
        font-family: "lexend-regular";
        font-size: var(--medium);
        color: white;
    }

    .overlay-content .active {
        text-decoration: none;
        font-family: "lexend-regular";
        font-size: var(--medium);
        color: var(--amarillo);
    }
}

@media (max-width:400px) {
    body header .logo img {
        height: 30px;
    }
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 50px;
    height: 90px;
    background-color: var(--azul-oscuro);
}

header .logo img {
    height: 50px;
    min-width: 100px;
    object-fit: cover;
    width: auto;
    cursor: pointer;
}

.lista-nav {
    display: flex;
    height: 50px;
    align-items: center;
    list-style: none;
}

.lista-nav li .link {
    text-decoration: none;
    color: white;
    font-family: "lexend-regular";
    font-size: var(--small);
    padding-left: 10px;
    padding-right: 10px;
    transition: all 0.25s ease;
}

.lista-nav li .link:hover {
    color: var(--amarillo);
}

.lista-nav li .active {
    text-decoration: none;
    color: var(--amarillo);
    font-family: "lexend-regular";
    font-size: var(--small);
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    border-bottom: 3px solid var(--amarillo);
}

.link-contactanos {
    padding: 10px 20px;
    border-radius: 10px;
    border: 3px solid var(--amarillo);
    cursor: pointer;
    color: white;
    transition: all 0.25s ease;
    margin-left: 10px;
}

.link-contactanos:hover {
    background-color: var(--amarillo);
    color: var(--azul-oscuro);
}

.overlay {
    display: none;
}

.menu {
    display: none;
    color: white;
}