@media (max-width:1000px) {
    body .ter-pqesc section {
        grid-template-columns: 300px 300px;
    }

    body .ter-pqesc section aside {
        font-size: var(--xxsmall);
    }
}

@media (max-width:600px) {
    body .ter-pqesc section {
        grid-template-columns: 300px;
        grid-template-rows: 300px 300px;

    }

    body .pri-pqesc h1 {
        font-size: var(--medium);
    }
}

.pri-pqesc {
    min-height: 500px;
    background-color: var(--azul-oscuro);
    color: white;
    padding: 50px;
}

.pri-pqesc h1 {
    font-family: "lexend-bold";
    font-size: var(--big);
    max-width: 600px;
    padding-bottom: 20px;
}

.pri-pqesc p {
    font-family: "arimo-regular";
    font-size: var(--small);
    max-width: 600px;
    padding-bottom: 20px;
}

.pri-pqesc button {
    font-family: "arimo-regular";
    border: 0;
    font-size: var(--small);
    background-color: var(--amarillo);
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
}

.sec-pqesc {
    min-height: 500px;
    padding: 50px;
    background-color: var(--celeste);
    color: var(--azul-oscuro);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.sec-pqesc h2 {
    font-size: var(--medium);
    font-family: "lexend-bold";
    padding-bottom: 10px;
}

.sec-pqesc p {
    max-width: 300px;
    padding-top: 10px;
    border-top: 5px solid var(--amarillo);
}

.ter-pqesc {
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ter-pqesc section {
    display: grid;
    grid-template-columns: 500px 500px;
    justify-items: stretch;
    align-items: stretch;
}

.ter-pqesc section image {
    object-fit: contain;
}

.ter-pqesc section aside {
    padding: 20px;
    background-color: var(--celeste);
    color: var(--azul-oscuro);
    font-family: "arimo-regular";
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ter-pqesc section aside span {
    padding: 10px 0px;
    border-radius: 20px;
    border: 2.5px solid var(--azul-oscuro);
    width: 120px;
    text-align: center;
    cursor: pointer;
    transition: all 0.25s ease;
}

.ter-pqesc section aside span:hover {
    background-color: var(--azul-oscuro);
    color: var(--celeste);
}



